<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.8)"
    style="margin: -15px; height: 85vh"
  >
    <p style="margin-top: 2vw">导入用户数据</p>

    <div class="hang">
      <el-input
        placeholder="请导入文件.excel"
        :disabled="true"
        v-model="file.name"
      >
      </el-input>
      <el-upload
        class="upload-demo"
        action="#"
        :on-preview="handlePreview"
        :before-upload="beforeUpload"
        :file-list="fileList"
        :show-file-list="false"
        :limit="1"
      >
        <el-button type="primary">选择文件</el-button>
      </el-upload>
    </div>
    <div class="hang">
      <el-button type="primary" @click="submit">提交</el-button>
    </div>

    <div class="hang">
      <div @click="down">
        <a download="drxsmb"> 下载<SPAN class="mb">模板</SPAN> </a>
      </div>
    </div>
  </div>
</template>


<script>
import { getBaseUrl } from "../../../requset/http.js";

export default {
  data() {
    return {
      input: "",
      cw: "",
      fileList: [],
      file: {},
      loading: false,
    };
  },
  methods: {
    handlePreview(file) {
      console.log(file);
    },
    beforeUpload(file) {
      this.file = file;
      return false;
    },
    submit() {
      // console.log(this.file.name);
      if (this.file.name == undefined) {
        this.$notify({
          title: "警告",
          message: "请添加文件后再提交",
          type: "warning",
        });
      } else {
        var formdata = new FormData();
        formdata.append("file", this.file);
        console.log(this.file);
        this.loading = true;
        this.$api.uuser.importScr(formdata, 60000)
          .then((res) => {
            this.loading = false;
            if (res.status != 0) {
              this.$notify({
                title: "添加成功",
                message: res.msg,
                type: "success",
              });
              this.$router.replace("/user");
            } else {
              this.$notify.error({
                title: "错误",
                message: res.msg,
              });
            }

            console.log(res);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    down() {
      window.location.href = getBaseUrl() + "/upload/excel/门店录入模板.xls";
      console.info(getBaseUrl() + "/upload/excel/门店录入模板.xls");
    },
  },
};
</script>


<style scoped>
.hang {
  margin: 15px;
  display: flex;
  justify-content: center;
}
.mb {
  color: #44bb44;
}

.el-input {
  width: 30vw;
}

p {
  margin: 0.5vw;
  font-size: 1.11vw;
  text-align: center;
}
</style>